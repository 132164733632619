/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const hiddenQueries = {
  pub_isVisibleForEveryone: 'true',
};

export const fields = [
  {
    id: 'title',
    label: 'Title',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'dr', label: 'Dr.' },
        { key: 'mr', label: 'Mr.' },
        { key: 'ms', label: 'Ms.' },
        { key: 'mx', label: 'Mx.' },
        { key: 'professor', label: 'Professor' },
      ],
    },
  },
  {
    id: 'st_title',
    label: 'Title',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'dr', label: 'Dr.' },
        { key: 'mr', label: 'Mr.' },
        { key: 'ms', label: 'Ms.' },
        { key: 'mx', label: 'Mx.' },
      ],
    },
  },
 /* {
    id: 'gender',
    label: 'gender',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'male', label: 'Male' },
        { key: 'female', label: 'Female' },
        { key: 'other', label: 'Other' },
      ],
    },
  }, */
  {
    id: 'pronoun',
    label: 'pronoun',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'blank', label: '' },
        { key: 'she', label: 'She/Her' },
        { key: 'shethey', label: 'She/They' },
        { key: 'he', label: 'He/Him' },
        { key: 'hethey', label: 'He/They' },
        { key: 'they', label: 'They/Them' },
        { key: 'not', label: 'Prefer not to answer' },
        { key: 'not', label: 'Other' },
      ],
    },
  },
  /* {
    id: 'st_gender',
    label: 'gender',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'male', label: 'Male' },
        { key: 'female', label: 'Female' },
        { key: 'not', label: 'Prefer not to answer' },
        { key: 'other', label: 'Other' },
      ],
    },
  },*/
  {
    id: 'st_pronoun',
    label: 'pronoun',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: 'blank', label: '' },
        { key: 'she', label: 'She/Her' },
        { key: 'shethey', label: 'She/They' },
        { key: 'he', label: 'He/Him' },
        { key: 'hethey', label: 'He/They' },
        { key: 'they', label: 'They/Them' },
        { key: 'not', label: 'Prefer not to answer' },
        { key: 'not', label: 'Other' },
      ],
    },
  },
  {
    id: 'st_degree',
    group: 'secondary',
    config: {
      options: [
        { key: 'phD', label: 'PhD' },
        { key: 'jd', label: 'JD' },
        { key: 'mba', label: 'MBA' },
        { key: 'masters', label: "Master's" },
        { key: 'bachelors', label: "Bachelor's" },
        { key: 'graduate', label: 'Graduate (currently enrolled)' },
        { key: 'undergraduate', label: 'Undergraduate (currently enrolled)' },
      ],
    },
  },
  {
    id: 'bufferTime',
    label: 'bufferTime',
    type: '',
    group: 'primary',
    config: {
      options: [
        { key: '0', label: 'None' },
        { key: '5', label: '5m' },
        { key: '10', label: '10m' },
      ],
    },
  },
];

export const filters = [
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 1,
    },
  },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  {
    id: 'category',
    label: 'Office Hours Types',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'academicAdvising', label: 'Academic Advising' },
        { key: 'careerGuidance', label: 'Career Guidance' },
        { key: 'graduateProfessionalSchool', label: 'Graduate School Mentoring' },
        { key: 'cvReview', label: 'CV/Resume Review' },
        { key: 'interviewPrep', label: 'Interview Preparation' },
        { key: 'subjectExpertise', label: 'Subject/Industry Expertise' },
        { key: 'applicationReview', label: `Application Review` },
        // { key: 'undergradAppReview', label: `Application Review (Undergraduate)` },
        // { key: `assignmentFeedback`, label: `Assignment Feedback` },
        { key: `essayFeedback`, label: `Writing/Essay Feedback` },
        { key: `infoInterview`, label:  `Informational Interview` },
        { key: `personalStatement`, label:`Personal Statement Review` },
        { key: `coverLetterReview`, label: `Cover Letter Review` },
        { key: `guidedReadings`, label: `Guided Readings` },
        // { key: `independentStudy`, label: `Independent Study` },
        // { key: `industryInsights`, label: `Industry Insights` },
        // { key: `professionalSchool`, label: `Thinking About Graduate/Professional School` },
        // { key: `thesisPlanning`, label: `Thesis Planning` },
        { key: `proposalReview`, label: `Proposal Review` },
        { key: `researchHelp`, label: `Research/Thesis Help` },
        // { key: `teachingTrainning`, label: `Teaching Training` },
        // { key: `syllabusReview`, label: `Syllabus Review` },
        // { key: `networkingAdvice`, label: `Networking Advice` },
        // { key: `academicCareerHelp`, label: `Academic Career Help` },
        { key: `nonAcademicCareerHelp`, label: `Non-Academic Careers for PhDs and Graduate Students` }
      ],
    },
    showAsPopupFilter: true,
  },

  {
    id: 'degree',
    label: 'Education',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_degree'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'phD', label: 'PhD' },
        { key: 'jd', label: 'JD' },
        { key: 'mba', label: 'MBA' },
        { key: 'masters', label: "Master's" },
        { key: 'bachelors', label: "Bachelor's" },
      ],
    },
    showAsPopupFilter: true,
  },
  {
    id: 'disciplinary',
    label: 'Disciplinary Backgrounds',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_disciplinary'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'arts', label: 'Arts & Humanities' },
        { key: 'law', label: 'Law' },
        { key: 'business', label: 'Business' },
        { key: 'socialSciences', label: 'Social Sciences' },
        { key: 'stem', label: 'STEM' },
      ],
    },
    showAsPopupFilter: true,
  },
  {
    id: 'language',
    label: 'Language',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      searchMode: 'has_any',
      options: [
        { key: 'english', label: 'English' },
        { key: 'german', label: 'German' },
        { key: 'chinese', label: 'Chinese (Mandarin)' },
        { key: 'arabic', label: 'Arabic' },
        { key: 'french', label: 'French' },
        { key: 'hindi', label: 'Hindi' },
        { key: 'italian', label: 'Italian' },
        { key: 'portuguese', label: 'Portuguese' },
        { key: 'polish', label: 'Polish' },
        { key: 'russian', label: 'Russian' },
        { key: 'spanish', label: 'Spanish' },
      ],
    },
    showAsPopupFilter: true,
  },
  {
    id: 'dates-length',
    label: 'Availability',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '30', label: '30 min', shortLabel: '30m' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
