// import axios from "axios";
// import {API_ENDPOINT} from "../config";
// const API_ENDPOINT = "http://localhost:8090";
const API_ENDPOINT = "https://oikos.polis.ac";

export async function getSharetribeUser(sharetribeID) {

  return fetch(API_ENDPOINT + '/users/sharetribe/get', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
    body: JSON.stringify({UserID: sharetribeID}),
  })
    .then((response) => response.json())
    .then(data => {
      console.log(data)
      return data.Payload;
  })

  // return axios.post(API_ENDPOINT  + '/users/sharetribe/get', params)
  //   .then(res => {
  //     return res.data.Payload
  //   })
}

// Extracts the cognito user id from the sharetribe user id
export async function getCognitoUserID(sharetribeID) {
  const sharetribeUser = await getSharetribeUser(sharetribeID)
  return sharetribeUser.data.attributes.identityProviders[0].userId
}
